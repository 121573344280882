/* You can add global styles to this file, and also import other style files */
@import '~firebaseui/dist/firebaseui.css';

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.page-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.avatar-thumbnail {
    height: 40px;
    border-radius: 50%;
}

.expansion-headers-align .mat-expansion-panel-header-description {
    justify-content: space-between;
    align-items: center;
}
  
.expansion-headers-align .mat-mdc-form-field + .mat-mdc-form-field {
    margin-left: 8px;
}